<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <a-card>
        <a-row style="margin-bottom: 20px;text-align: left">
          <a-range-picker @change="timeChange" :placeholder="[l('WEStartDate'), l('WEEndDate')]" v-model="defaultRangeTime"
                          style="margin-right:10px; width: 250px;margin-bottom: 5px"/>
          <a-tree-select
              style="width: 600px;margin-right:10px;margin-bottom: 5px"
              v-model="workProcessDataKey"
              :tree-data="workProcessData"
              :replace-fields="treeReplace"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :placeholder="l('Please_select_a_audit_point')"
              allow-clear
              tree-checkable
              tree-default-expand-all
              @change="workProcessClassChange"
          />
          <a-checkbox @change="onChange" style="margin-right: 10px;">
            {{l('Point_Details')}}
          </a-checkbox>
          <a-button type="primary" @click="getData">
            {{ l('QueryUserDownloadConfig')}}
          </a-button>
        </a-row>
        <a-spin :spinning="isLoading">
          <div style="height: 500px;width: 100%;border-top: 1px solid #dfdfdf;border-bottom: 1px solid #dfdfdf;text-align: left" id="mainScrapCode">

          </div>
        </a-spin>
      </a-card>
    </a-config-provider>
  </div>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { TreeSelect } from 'ant-design-vue';
import { AuditPointServiceProxy,AuditTaskServiceProxy } from '../../../shared/service-proxies';
import * as echarts from 'echarts';
import moment from "moment";

let SHOW_PARENT = TreeSelect.SHOW_PARENT;
let _this;
export default {
  name: "Line-chart",
  mixins: [AppComponentBase],
  components: {

  },
  created() {
    _this = this;
    this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
    this.AuditTaskServiceProxy = new AuditTaskServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this.getWorkProcessData();
    this.LineChartOutline();
    this.nowTime();
  },
  data() {
    return {
      zh_CN,
      isLoading: true,//加载中
      workProcessData: [],
      workProcessClass: [],
      treeReplace: {
        title: 'name',
        key: 'id',
        value: 'id'
      },
      chartData: {

      },
      chart: [],
      timeData: [],
      nameData: [],
      name: undefined,
      startdate: undefined,
      enddate: undefined,
      NowTime1: undefined,
      NowTime2: undefined,
      defaultRangeTime: [],
      workProcessDataKey: undefined,
      IsPointDetail: false,
    }
  },
  computed: {
    // 指定图表的配置项和数据
    option(){
      return {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: this.nameData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timeData
        },
        yAxis: {
          type: 'value',
        },
        series: this.chart
      }
    }
  },
  methods: {
    moment,
    nowTime() {
      let aData = new Date();
      this.NowTime1 = new Date(aData.setMonth(aData.getMonth() - 1));
      this.NowTime2 = new Date();
      this.defaultRangeTime = [moment(this.NowTime1),moment(this.NowTime2)];
      this.startdate = this.NowTime1;
      this.enddate = this.NowTime2;
    },
    LineChartOutline() {
      console.log(this.chart)
      console.log(this.nameData)
      this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

      this.myeChart.clear();
      // 使用刚指定的配置项和数据显示图表。
      this.myeChart.setOption(this.option);
	  this.myeChart.resize();
      window.addEventListener('resize', () => {
        this.myeChart.resize();
      })
    },
    //获取审核点
    getWorkProcessData() {
      this.isLoading = true;
      this.AuditPointServiceProxy.getAllData(
      ).finally(() => {
        this.isLoading = false;
      }).then(res => {
        console.log(res);
        this.workProcessData = JSON.parse(JSON.stringify(res));
		this.workProcessDataKey = this.workProcessData[0].key;
		if(this.workProcessData[0].children !=undefined ){
			let s = this.workProcessData[0].children.length;
			let data = [];
			for(let i =0;i<s;i++) {
				let a = this.workProcessData[0].children[i].id;
				data.push(a)
			}
			this.workProcessClass = data;
		} else if(this.workProcessData[0].children == undefined){
			this.workProcessClass.push(this.workProcessDataKey);
		}
		console.log(this.workProcessClass);
        this.getData();
      })
    },
    onChange(e) {
      console.log(e.target.checked);
      this.IsPointDetail = e.target.checked;
    },
    workProcessClassChange(key) {
      console.log(key);
      if (key.length==0) {
        // debugger
        this.workProcessClass = [];
        console.log(this.workProcessClass)
      }else {
        this.workProcessClass = key;
      }
    },
    timeChange(date, dateString) {
      this.startdate = date[0];
      this.enddate = date[1];
      // this.getData();
    },
    getData() {
      console.log(this.workProcessClass)
      this.chart = [];
      this.nameData = [];
      this.isLoading = true;
      this.AuditTaskServiceProxy.getPointAuditStatisticsCount(
          this.IsPointDetail,
          this.workProcessClass,
          this.startdate,
          this.enddate
      ).finally(() => {
        this.isLoading = false;
      }).then(res => {
        console.log(res);
        this.timeData = res[0].date;
        for(let i=0;i<res.length;i++){
          let chartData = {};
          // chartData.showSymbol = false,
          chartData.name = res[i].pointName;
          chartData.data = res[i].count;
          chartData.type = 'line';
          // chartData.stack = 'Total';
		  chartData.label = {
		  	show: true,
		  	textBorderWidth: '0',
		  	formatter: function(data){
		  		if(data.value>0) {
		  			return data.value + '%'
		  		}else {
		  			return ""
		  		}
		  	}
		  };
          this.chart.push(chartData);
          this.nameData.push(res[i].pointName);
        }
        // console.log(this.chart);
        this.LineChartOutline();
      })
    },
  }
}
</script>

<style>
</style>
